import checkPhoneAdapter from '@/features/auth/requests/adapters/check-phone-adapter';
import { type CheckPhoneResponse } from '@/features/auth/types/check-phone-respoonse-types';
import { type CheckPhone } from '@/features/auth/types/check-phone-types';
import sendHttpError from '@/libs/logger/requests/send-http-error';

export default async function getCheckPhone(phoneNumber: string): Promise<CheckPhone> {
  const url = `${process.env.NEXT_PUBLIC_API_URL}/v1/CheckPhone?phone_number=${phoneNumber}`;

  const phoneCheckResponse = await fetch(
    url,
    { cache: 'no-store' },
  );

  // Server send 404 if user is not registered with data PhoneSignUpResponse
  if (!phoneCheckResponse.ok && phoneCheckResponse.status !== 404) {
    sendHttpError({
      baseName: 'Check phone number',
      functionName: 'getCheckPhone',
      method: 'GET',
      statusCode: phoneCheckResponse.status,
      url,
    });
    throw new Error('Check phone failed');
  }

  const phoneCheckData = await phoneCheckResponse.json() as CheckPhoneResponse;

  return checkPhoneAdapter(phoneCheckData);
}
