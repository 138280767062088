import { type CountryWithPhoneCode } from '@/features/place/types/place-types';
import sendHttpError from '@/libs/logger/requests/send-http-error';

interface GetValidatePhoneResponse {
  errorId: null | string;
  data: null | string ;
}

export default async function getValidatePhone(phoneNumber: string, phoneLocale: CountryWithPhoneCode['shortName']): Promise<GetValidatePhoneResponse> {
  const url = `${process.env.NEXT_PUBLIC_API_URL}/v1/ValidatePhone?phone_number=${phoneNumber}&locale=${phoneLocale}`;

  const phoneValidateResponse = await fetch(
    url,
    { cache: 'no-store' },
  );

  // Too Many Requests (429) if user is trying to validate phone too many times
  if (phoneValidateResponse.status === 429 || phoneValidateResponse.status === 400) {
    return { data: null, errorId: 'too-many-attempts' };
  }

  if (!phoneValidateResponse.ok) {
    sendHttpError({
      baseName: 'Validate phone',
      functionName: 'getValidatePhone',
      method: 'GET',
      statusCode: phoneValidateResponse.status,
      url,
    });
    throw new Error('Validate phone failed');
  }

  const phoneValidateData = await phoneValidateResponse.json() as string;

  return { data: phoneValidateData, errorId: null };
}
