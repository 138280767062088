import { useSetAtom } from 'jotai/index';
import { useTranslations } from 'next-intl';
import { type ChangeEvent } from 'react';
import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import {
  analyticEventCreateAccount,
  analyticSetUser,
} from '@/features/analytic/enteties/analytic';
import postPhoneSignUp from '@/features/auth/requests/post-phone-sign-up';
import { type PhoneSignUpData } from '@/features/auth/types/auth-types';
import { getKeywordCookie } from '@/features/roomster-app/cookies/cookie-keyword';
import { getReferralCookie } from '@/features/roomster-app/cookies/cookie-referral';
import { loggedUserAtom } from '@/features/user/atoms/logged-user-atom';
import { setUserBearerCookie } from '@/features/user/cookies/cookie-user-bearer';
import initUserIdVerification from '@/features/user/requests/init-user-id-verification';
import {
  type UserBirthday,
  type UserBirthdayInputName,
} from '@/features/user/types/user-profile-types';
import { type GENDERS_KEYS } from '@/features/user/types/user-types';
import { useRouter } from '@/i18n/routing';
import { ROUTER_LINKS } from '@/shared/constants/links-constants';
import Button from '@/ui/button/button';
import InputBirthDate from '@/ui/form-elements/input-birth-date/input-birth-date';
import InputGender from '@/ui/form-elements/input-gender/input-gender';
import InputText from '@/ui/form-elements/input-text/input-text';

import styles from './phone-sign-up-form.module.scss';

interface InputData<T> {
  error: string | null;
  value: T;
}

interface PhoneSignUpFormProps {
  phoneNumber: string;
}

export default function PhoneSignUpForm(props: PhoneSignUpFormProps) {
  const { phoneNumber } = props;

  const setLoggedUserData = useSetAtom(loggedUserAtom);

  const translationsActions = useTranslations('global.actions');
  const translationsFromErrors = useTranslations('global.form.errors');
  const translationsUser = useTranslations('user');
  const translationsAuth = useTranslations('auth');

  const router = useRouter();

  const onlyLettersPattern = /^[\sA-Za-z]*$/;

  const [firstName,
    setFirstName] = useState<InputData<string>>({ error: null, value: '' });

  const [lastName,
    setLastName] = useState<InputData<string>>({ error: null, value: '' });

  const [gender,
    setGender] = useState<InputData<GENDERS_KEYS | 'Unknown' | null>>({ error: null, value: null });

  const [birthDate,
    setBirthDate] = useState<InputData<Partial<UserBirthday> | null>>({
    error: null,
    value: {
      birth_day: null,
      birth_month: null,
      birth_year: null,
    },
  });

  const [isBDValid,
    setIsBDValid] = useState<boolean>(true);

  const [recaptcha,
    setRecaptcha] = useState<InputData<boolean>>({ error: null, value: false });

  const [isLoading,
    setIsLoading] = useState<boolean>(false);

  const handleFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (value.length > 50) {
      setFirstName({
        error: translationsFromErrors('max-length', { length: 50 }),
        value: firstName.value,
      });
    } else if (onlyLettersPattern.test(value)) {
      setFirstName({
        error: null,
        value,
      });
    }
  };

  const handleLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (value.length > 50) {
      setLastName({
        error: translationsFromErrors('max-length', { length: 50 }),
        value: lastName.value,
      });
    } else if (onlyLettersPattern.test(value)) {
      setLastName({
        error: null,
        value,
      });
    }
  };

  const handleGenderChange = (value: GENDERS_KEYS | 'Unknown' | null) => {
    setGender({
      error: null,
      value,
    });
  };

  const handleBirthDateChange = (inputName: UserBirthdayInputName, value: number) => {
    setBirthDate({
      ...birthDate,
      value: {
        ...birthDate.value,
        [inputName]: value,
      },
    });
  };

  const handleBDErrors = (isError: boolean) => {
    setIsBDValid(!isError);
  };

  const handleRecaptchaChange = (data: string | null) => {
    if (data) {
      setRecaptcha({
        error: null,
        value: true,
      });
    } else {
      setRecaptcha({
        error: translationsFromErrors('recaptcha-failed'),
        value: false,
      });
    }
  };

  const handleSignUp = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      let isFromValid = true;

      if (!firstName.value.trim()) {
        setFirstName({ ...firstName, error: translationsFromErrors('required') });
        isFromValid = false;
      }
      if (!lastName.value.trim()) {
        setLastName({ ...lastName, error: translationsFromErrors('required') });
        isFromValid = false;
      }
      if (!gender.value) {
        setGender({ ...gender, error: translationsFromErrors('required') });
        isFromValid = false;
      }
      if (!birthDate.value?.birth_year || !birthDate.value.birth_month || !birthDate.value.birth_day) {
        setBirthDate({ ...birthDate, error: translationsFromErrors('required') });
        isFromValid = false;
      }
      if (!recaptcha.value) {
        setRecaptcha({ error: translationsFromErrors('recaptcha-required'), value: false });
        isFromValid = false;
      }

      if (isFromValid) {
        setIsLoading(true);
        // Was pick from ald app
        const HARDCODED_SERVICE_TYPE = 'Undefined';
        const referrer = getReferralCookie();
        const keyword = getKeywordCookie();

        const phoneSignUpData: PhoneSignUpData = {
          // @ts-expect-error we already checked that value is not null
          birth_day: birthDate.value.birth_day,
          // @ts-expect-error we already checked that value is not null
          birth_month: birthDate.value.birth_month,
          // @ts-expect-error we already checked that value is not null
          birth_year: birthDate.value.birth_year,
          first_name: firstName.value,
          // @ts-expect-error we already checked that value is not null
          gender: gender.value,
          last_name: lastName.value,
          referrer: referrer || '',
          service_type: HARDCODED_SERVICE_TYPE,
          validated_phone: phoneNumber,
        };

        if (keyword) {
          // eslint-disable-next-line canonical/id-match
          phoneSignUpData.ref_keyword = keyword;
        }

        const postPhoneSignUpResponse = await postPhoneSignUp(phoneSignUpData);

        if (postPhoneSignUpResponse.data) {
          const registeredUser = postPhoneSignUpResponse.data.user;
          analyticEventCreateAccount();
          analyticSetUser(registeredUser.id);
          await initUserIdVerification(postPhoneSignUpResponse.data.accessToken);

          setUserBearerCookie(postPhoneSignUpResponse.data.accessToken, postPhoneSignUpResponse.data.expiresInSeconds);
          setLoggedUserData({
            ...registeredUser,
            missingFields: ['photo'],
          });

          router.push(`${ROUTER_LINKS.listingsAdd}?isFirstListing=true`);
        }
      }
    })();
  };

  return (
    <form>
      <div className={styles.phoneSignUpForm_field}>
        <InputText
          label={translationsUser('first-name')}
          value={firstName.value}
          onChange={handleFirstNameChange}
          isError={!!firstName.error}
          disabled={isLoading}
        />

        {firstName.error && (
          <p className={styles.phoneSignUpForm_error}>
            {firstName.error}
          </p>
        )}
      </div>

      <div className={styles.phoneSignUpForm_field}>
        <InputText
          label={translationsUser('last-name')}
          value={lastName.value}
          onChange={handleLastNameChange}
          isError={!!lastName.error}
          disabled={isLoading}
        />

        {lastName.error && (
          <p className={styles.phoneSignUpForm_error}>
            {lastName.error}
          </p>
        )}
      </div>

      <div className={styles.phoneSignUpForm_field}>
        <p className={styles.phoneSignUpForm_label}>
          {translationsUser('gender')}
        </p>

        <InputGender
          value={gender.value}
          onGenderSelectChange={handleGenderChange}
          isDisabled={isLoading}
          size="lg"
        />

        {gender.error && (
          <p className={styles.phoneSignUpForm_error}>
            {gender.error}
          </p>
        )}
      </div>

      <div className={styles.phoneSignUpForm_field}>
        <p className={styles.phoneSignUpForm_label}>Birthday</p>

        <InputBirthDate
          birthDate={birthDate.value}
          onBirthDateChange={handleBirthDateChange}
          onErrorsUpdate={handleBDErrors}
          isDisabled={isLoading}
          size="lg"
        />

        {birthDate.error && (
          <p className={styles.phoneSignUpForm_error}>
            {birthDate.error}
          </p>
        )}
      </div>

      <div className={styles.phoneSignUpForm_recaptcha}>
        <ReCAPTCHA
          sitekey={process.env.NEXT_PUBLIC_GOOGLE_SITE_KEY}
          onChange={handleRecaptchaChange}
        />

        {recaptcha.error && (
          <p className={styles.phoneSignUpForm_recaptchaError}>
            {recaptcha.error}
          </p>
        )}
      </div>

      <p className={styles.phoneSignUpForm_termsPrivacy}>
        {translationsAuth.rich('signup-terms-privacy', {
          /* eslint-disable-next-line react/no-unstable-nested-components */
          linkPrivacy: (chunks) => (
            <a
              className={styles.phoneSignUpForm_termsPrivacyLink}
              href={ROUTER_LINKS.privacy}
            >
              {chunks}
            </a>
          ),
          /* eslint-disable-next-line react/no-unstable-nested-components */
          linkTerms: (chunks) => (
            <a
              className={styles.phoneSignUpForm_termsPrivacyLink}
              href={ROUTER_LINKS.terms}
            >
              {chunks}
            </a>
          ),
        })}
      </p>

      <Button
        element="button"
        fullWidth
        size="lg"
        onClick={handleSignUp}
        isLoading={isLoading}
        disabled={Boolean(isLoading || !isBDValid)}
      >
        {translationsActions('sign-up')}
      </Button>
    </form>
  );
}
