'use client';

import './input-gender.scss';

import cx from 'classix';
import { useTranslations } from 'next-intl';
import {
  useEffect,
  useRef,
} from 'react';
import Select, {
  type SelectInstance,
  type SingleValue,
} from 'react-select';
import { useIsMounted } from 'usehooks-ts';

import { GENDERS_KEYS } from '@/features/user/types/user-types';
import { type UiSizesTypes } from '@/shared/types/ui-sizes-types';

interface GenderOption {
  label: string;
  value: GENDERS_KEYS | 'Unknown';
}

interface InputGenderProps {
  value: GENDERS_KEYS | 'Unknown' | null;
  autoFocus?: boolean;
  isDisabled?: boolean;
  onGenderSelectChange: (value: GENDERS_KEYS | 'Unknown') => void;
  size?: UiSizesTypes;
}

export default function InputGender(props: InputGenderProps) {
  const {
    autoFocus,
    isDisabled,
    onGenderSelectChange,
    size,
    value,
  } = props;

  const isMounted = useIsMounted();

  const translationsUser = useTranslations('user');

  const male = {
    label: translationsUser('identifier.he'),
    value: GENDERS_KEYS.Male,
  };

  const female = {
    label: translationsUser('identifier.she'),
    value: GENDERS_KEYS.Female,
  };

  const none: { label: string, value: 'Unknown' } = {
    label: translationsUser('identifier.none'),
    value: 'Unknown',
  };

  const userGenderVariants = {
    [GENDERS_KEYS.Male]: male,
    [GENDERS_KEYS.Female]: female,
    Unknown: none,
  };

  const options = [male, female, none] as const;

  const selectedGender = value ? userGenderVariants[value] : undefined;

  const genderSelectRef = useRef<SelectInstance<GenderOption>>(null);

  const handleGenderSelectChange = (option: SingleValue<GenderOption>) => {
    if (option) {
      onGenderSelectChange(option.value);
    }
  };

  useEffect(() => {
    if (autoFocus) {
      genderSelectRef.current?.focus();
    }
  }, [autoFocus, isMounted]);

  return (
    <Select
      className={cx('genderSelect', size && `genderSelect__${size}`)}
      classNamePrefix="genderSelect"
      ref={genderSelectRef}
      defaultValue={selectedGender}
      onChange={handleGenderSelectChange}
      name="identifyselect"
      options={options}
      isDisabled={isDisabled}
      isSearchable={false}
      placeholder={null}
    />
  );
}
