import { type CountryWithPhoneCode } from '@/features/place/types/place-types';

export const COUNTRIES_WITH_PHONE_CODE: CountryWithPhoneCode[] = [
  {
    id: 165, name: 'Afghanistan', phoneCode: '+93', shortName: 'AF',
  },
  {
    id: 117,
    name: 'Albania',
    phoneCode: '+355',
    shortName: 'AL',
  },
  {
    id: 234, name: 'Algeria', phoneCode: '+213', shortName: 'DZ',
  },
  {
    id: 346,
    name: 'Andorra',
    phoneCode: '+376',
    shortName: 'AD',
  },
  {
    id: 235, name: 'Angola', phoneCode: '+244', shortName: 'AO',
  },
  {
    id: 365,
    name: 'Anguilla',
    phoneCode: '+1 264',
    shortName: 'AI',
  },
  {
    id: 293, name: 'Antarctica', phoneCode: '+672', shortName: 'AQ',
  },
  {
    id: 343,
    name: 'Antigua and Barbuda',
    phoneCode: '+1 268',
    shortName: 'AG',
  },
  {
    id: 95, name: 'Argentina', phoneCode: '+54', shortName: 'AR',
  },
  {
    id: 166,
    name: 'Armenia',
    phoneCode: '+374',
    shortName: 'AM',
  },
  {
    id: 360, name: 'Aruba', phoneCode: '+297', shortName: 'AW',
  },
  {
    id: 294,
    name: 'Australia',
    phoneCode: '+61',
    shortName: 'AU',
  },
  {
    id: 118, name: 'Austria', phoneCode: '+43', shortName: 'AT',
  },
  {
    id: 345,
    name: 'Azerbaijan',
    phoneCode: '+994',
    shortName: 'AZ',
  },
  {
    id: 348, name: 'Bahamas', phoneCode: '+1 242', shortName: 'BS',
  },
  {
    id: 167,
    name: 'Bahrain',
    phoneCode: '+973',
    shortName: 'BH',
  },
  {
    id: 168, name: 'Bangladesh', phoneCode: '+880', shortName: 'BD',
  },
  {
    id: 347,
    name: 'Barbados',
    phoneCode: '+1 246',
    shortName: 'BB',
  },
  {
    id: 119, name: 'Belarus', phoneCode: '+375', shortName: 'BY',
  },
  {
    id: 120,
    name: 'Belgium',
    phoneCode: '+32',
    shortName: 'BE',
  },
  {
    id: 96, name: 'Belize', phoneCode: '+501', shortName: 'BZ',
  },
  {
    id: 237,
    name: 'Benin',
    phoneCode: '+229',
    shortName: 'BJ',
  },
  {
    id: 353, name: 'Bermuda', phoneCode: '+1 441', shortName: 'BM',
  },
  {
    id: 366,
    name: 'Bhutan',
    phoneCode: '+975',
    shortName: 'BT',
  },
  {
    id: 97, name: 'Bolivia', phoneCode: '+591', shortName: 'BO',
  },
  {
    id: 121,
    name: 'Bosnia',
    phoneCode: '+387',
    shortName: 'BA',
  },
  {
    id: 238, name: 'Botswana', phoneCode: '+267', shortName: 'BW',
  },
  {
    id: 98,
    name: 'Brazil',
    phoneCode: '+55',
    shortName: 'BR',
  },
  {
    id: 169, name: 'Brunei', phoneCode: '+673', shortName: 'BN',
  },
  {
    id: 122,
    name: 'Bulgaria',
    phoneCode: '+359',
    shortName: 'BG',
  },
  {
    id: 239, name: 'Burkina Faso', phoneCode: '+226', shortName: 'BF',
  },
  {
    id: 170,
    name: 'Burma',
    phoneCode: '+95',
    shortName: 'MM',
  },
  {
    id: 240, name: 'Burundi', phoneCode: '+257', shortName: 'BI',
  },
  {
    id: 244,
    name: 'C. African Rep.',
    phoneCode: '+236',
    shortName: 'CF',
  },
  {
    id: 171, name: 'Cambodia', phoneCode: '+855', shortName: 'KH',
  },
  {
    id: 241,
    name: 'Cameroon',
    phoneCode: '+237',
    shortName: 'CM',
  },
  {
    id: 2, name: 'Canada', phoneCode: '+1', shortName: 'CA',
  },
  {
    id: 243,
    name: 'Cape Verde Is.',
    phoneCode: '+238',
    shortName: 'CV',
  },
  {
    id: 349, name: 'Cayman Islands', phoneCode: '+1 345', shortName: 'KY',
  },
  {
    id: 245,
    name: 'Chad',
    phoneCode: '+235',
    shortName: 'TD',
  },
  {
    id: 99, name: 'Chile', phoneCode: '+56', shortName: 'CL',
  },
  {
    id: 172,
    name: 'China',
    phoneCode: '+86',
    shortName: 'CN',
  },
  {
    id: 100, name: 'Colombia', phoneCode: '+57', shortName: 'CO',
  },
  {
    id: 246,
    name: 'Comoros',
    phoneCode: '+269',
    shortName: 'KM',
  },
  {
    id: 247, name: 'Congo', phoneCode: '+242', shortName: 'CG',
  },
  {
    id: 368,
    name: 'Cook Islands',
    phoneCode: '+682',
    shortName: 'CK',
  },
  {
    id: 101, name: 'Costa Rica', phoneCode: '+506', shortName: 'CR',
  },
  {
    id: 123,
    name: 'Croatia',
    phoneCode: '+385',
    shortName: 'HR',
  },
  {
    id: 74, name: 'Cuba', phoneCode: '+53', shortName: 'CU',
  },
  {
    id: 124,
    name: 'Cyprus',
    phoneCode: '+357',
    shortName: 'CY',
  },
  {
    id: 125, name: 'Czech Republic', phoneCode: '+420', shortName: 'CZ',
  },
  {
    id: 126,
    name: 'Denmark',
    phoneCode: '+45',
    shortName: 'DK',
  },
  {
    id: 248,
    name: 'Djibouti',
    phoneCode: '+253',
    shortName: 'DJ',
  },
  {
    id: 75,
    name: 'Dominica',
    phoneCode: '+1 767',
    shortName: 'DM',
  },
  {
    id: 76, name: 'Dominican Republic', phoneCode: '+1', shortName: 'DO',
  },
  {
    id: 103,
    name: 'Ecuador',
    phoneCode: '+593',
    shortName: 'EC',
  },
  {
    id: 249, name: 'Egypt', phoneCode: '+20', shortName: 'EG',
  },
  {
    id: 104,
    name: 'El Salvador',
    phoneCode: '+503',
    shortName: 'SV',
  },
  {
    id: 250, name: 'Eq. Guinea', phoneCode: '+240', shortName: 'GQ',
  },
  {
    id: 251,
    name: 'Eritrea',
    phoneCode: '+291',
    shortName: 'ER',
  },
  {
    id: 128, name: 'Estonia', phoneCode: '+372', shortName: 'EE',
  },
  {
    id: 252,
    name: 'Ethiopia',
    phoneCode: '+251',
    shortName: 'ET',
  },
  {
    id: 173, name: 'Fiji Islands', phoneCode: '+679', shortName: 'FJ',
  },
  {
    id: 129,
    name: 'Finland',
    phoneCode: '+358',
    shortName: 'FI',
  },
  {
    id: 302, name: 'Fr. Polynesia', phoneCode: '+689', shortName: 'PF',
  },
  {
    id: 130,
    name: 'France',
    phoneCode: '+33',
    shortName: 'FR',
  },
  {
    id: 254, name: 'Gabon', phoneCode: '+241', shortName: 'GA',
  },
  {
    id: 255,
    name: 'Gambia',
    phoneCode: '+220',
    shortName: 'GM',
  },
  {
    id: 131, name: 'Georgia', phoneCode: '+995', shortName: 'GE',
  },
  {
    id: 132,
    name: 'Germany',
    phoneCode: '+49',
    shortName: 'DE',
  },
  {
    id: 256, name: 'Ghana', phoneCode: '+233', shortName: 'GH',
  },
  {
    id: 133,
    name: 'Gibraltar',
    phoneCode: '+350',
    shortName: 'GI',
  },
  {
    id: 134, name: 'Greece', phoneCode: '+30', shortName: 'GR',
  },
  {
    id: 135,
    name: 'Greenland',
    phoneCode: '+299',
    shortName: 'GL',
  },
  {
    id: 356, name: 'Grenada', phoneCode: '+1 473', shortName: 'GD',
  },
  {
    id: 369,
    name: 'Guadeloupe',
    phoneCode: '+590',
    shortName: 'GP',
  },
  {
    id: 175, name: 'Guam', phoneCode: '+1 671', shortName: 'GU',
  },
  {
    id: 106,
    name: 'Guatemala',
    phoneCode: '+502',
    shortName: 'GT',
  },
  {
    id: 257, name: 'Guinea', phoneCode: '+224', shortName: 'GN',
  },
  {
    id: 258,
    name: 'Guinea-Bissau',
    phoneCode: '+245',
    shortName: 'GW',
  },
  {
    id: 107, name: 'Guyana', phoneCode: '+592', shortName: 'GY',
  },
  {
    id: 80,
    name: 'Haiti',
    phoneCode: '+509',
    shortName: 'HT',
  },
  {
    id: 363,
    name: 'Holy See (Vatican City State)',
    phoneCode: '+39',
    shortName: 'VA',
  },
  {
    id: 108,
    name: 'Honduras',
    phoneCode: '+504',
    shortName: 'HN',
  },
  {
    id: 350, name: 'Hong Kong', phoneCode: '+852', shortName: 'HK',
  },
  {
    id: 136,
    name: 'Hungary',
    phoneCode: '+36',
    shortName: 'HU',
  },
  {
    id: 137, name: 'Iceland', phoneCode: '+354', shortName: 'IS',
  },
  {
    id: 176,
    name: 'India',
    phoneCode: '+91',
    shortName: 'IN',
  },
  {
    id: 177, name: 'Indonesia', phoneCode: '+62', shortName: 'ID',
  },
  {
    id: 178,
    name: 'Iran',
    phoneCode: '+98',
    shortName: 'IR',
  },
  {
    id: 179, name: 'Iraq', phoneCode: '+964', shortName: 'IQ',
  },
  {
    id: 138,
    name: 'Ireland',
    phoneCode: '+353',
    shortName: 'IE',
  },
  {
    id: 180, name: 'Israel', phoneCode: '+972', shortName: 'IL',
  },
  {
    id: 139,
    name: 'Italy',
    phoneCode: '+39',
    shortName: 'IT',
  },
  {
    id: 259, name: 'Ivory Coast', phoneCode: '+225', shortName: 'CI',
  },
  {
    id: 81,
    name: 'Jamaica',
    phoneCode: '+1 876',
    shortName: 'JM',
  },
  {
    id: 181, name: 'Japan', phoneCode: '+81', shortName: 'JP',
  },
  {
    id: 370,
    name: 'Jersey',
    phoneCode: '+44',
    shortName: 'JE',
  },
  {
    id: 182, name: 'Jordan', phoneCode: '+962', shortName: 'JO',
  },
  {
    id: 183,
    name: 'Kazakhstan',
    phoneCode: '+7',
    shortName: 'KZ',
  },
  {
    id: 261, name: 'Kenya', phoneCode: '+254', shortName: 'KE',
  },
  {
    id: 185,
    name: 'Kuwait',
    phoneCode: '+965',
    shortName: 'KW',
  },
  {
    id: 186, name: 'Kyrghyzstan', phoneCode: '+996', shortName: 'KG',
  },
  {
    id: 187,
    name: 'Laos',
    phoneCode: '+856',
    shortName: 'LA',
  },
  {
    id: 141, name: 'Latvia', phoneCode: '+371', shortName: 'LV',
  },
  {
    id: 188,
    name: 'Lebanon',
    phoneCode: '+961',
    shortName: 'LB',
  },
  {
    id: 262, name: 'Lesotho', phoneCode: '+266', shortName: 'LS',
  },
  {
    id: 263,
    name: 'Liberia',
    phoneCode: '+231',
    shortName: 'LR',
  },
  {
    id: 264, name: 'Libya', phoneCode: '+218', shortName: 'LY',
  },
  {
    id: 351,
    name: 'Liechtenstein',
    phoneCode: '+423',
    shortName: 'LI',
  },
  {
    id: 142, name: 'Lithuania', phoneCode: '+370', shortName: 'LT',
  },
  {
    id: 143,
    name: 'Luxembourg',
    phoneCode: '+352',
    shortName: 'LU',
  },
  {
    id: 189, name: 'Macao', phoneCode: '+853', shortName: 'MO',
  },
  {
    id: 144,
    name: 'Macedonia',
    phoneCode: '+389',
    shortName: 'MK',
  },
  {
    id: 265, name: 'Madagascar', phoneCode: '+261', shortName: 'MG',
  },
  {
    id: 266,
    name: 'Malawi',
    phoneCode: '+265',
    shortName: 'MW',
  },
  {
    id: 190, name: 'Malaysia', phoneCode: '+60', shortName: 'MY',
  },
  {
    id: 191,
    name: 'Maldives',
    phoneCode: '+960',
    shortName: 'MV',
  },
  {
    id: 267, name: 'Mali', phoneCode: '+223', shortName: 'ML',
  },
  {
    id: 145,
    name: 'Malta',
    phoneCode: '+356',
    shortName: 'MT',
  },
  {
    id: 371, name: 'Martinique', phoneCode: '+596', shortName: 'MQ',
  },
  {
    id: 269,
    name: 'Mauritania',
    phoneCode: '+222',
    shortName: 'MR',
  },
  {
    id: 270, name: 'Mauritius', phoneCode: '+230', shortName: 'MU',
  },
  {
    id: 66,
    name: 'Mexico',
    phoneCode: '+52',
    shortName: 'MX',
  },
  {
    id: 298, name: 'Micronesia', phoneCode: '+691', shortName: 'FM',
  },
  {
    id: 344,
    name: 'Moldova, Republic of',
    phoneCode: '+373',
    shortName: 'MD',
  },
  {
    id: 146, name: 'Monaco', phoneCode: '+377', shortName: 'MC',
  },
  {
    id: 192,
    name: 'Mongolia',
    phoneCode: '+976',
    shortName: 'MN',
  },
  {
    id: 147, name: 'Montenegro', phoneCode: '+382', shortName: 'ME',
  },
  {
    id: 361,
    name: 'Montserrat',
    phoneCode: '+1 664',
    shortName: 'MS',
  },
  {
    id: 271, name: 'Morocco', phoneCode: '+212', shortName: 'MA',
  },
  {
    id: 272,
    name: 'Mozambique',
    phoneCode: '+258',
    shortName: 'MZ',
  },
  {
    id: 273, name: 'Namibia', phoneCode: '+264', shortName: 'NA',
  },
  {
    id: 193,
    name: 'Nepal',
    phoneCode: '+977',
    shortName: 'NP',
  },
  {
    id: 148, name: 'Netherlands', phoneCode: '+31', shortName: 'NL',
  },
  {
    id: 194,
    name: 'New Caledonia',
    phoneCode: '+687',
    shortName: 'NC',
  },
  {
    id: 300, name: 'New Zealand', phoneCode: '+64', shortName: 'NZ',
  },
  {
    id: 109,
    name: 'Nicaragua',
    phoneCode: '+505',
    shortName: 'NI',
  },
  {
    id: 274, name: 'Niger', phoneCode: '+227', shortName: 'NE',
  },
  {
    id: 275,
    name: 'Nigeria',
    phoneCode: '+234',
    shortName: 'NG',
  },
  {
    id: 184, name: 'North Korea', phoneCode: '+850', shortName: 'KP',
  },
  {
    id: 149,
    name: 'Norway',
    phoneCode: '+47',
    shortName: 'NO',
  },
  {
    id: 195, name: 'Oman', phoneCode: '+968', shortName: 'OM',
  },
  {
    id: 301,
    name: 'P. New Guinea',
    phoneCode: '+675',
    shortName: 'PG',
  },
  {
    id: 196, name: 'Pakistan', phoneCode: '+92', shortName: 'PK',
  },
  {
    id: 342,
    name: 'Palestine',
    phoneCode: '+970',
    shortName: 'PS',
  },
  {
    id: 110, name: 'Panama', phoneCode: '+507', shortName: 'PA',
  },
  {
    id: 111,
    name: 'Paraguay',
    phoneCode: '+595',
    shortName: 'PY',
  },
  {
    id: 112, name: 'Peru', phoneCode: '+51', shortName: 'PE',
  },
  {
    id: 197,
    name: 'Philippines',
    phoneCode: '+63',
    shortName: 'PH',
  },
  {
    id: 150, name: 'Poland', phoneCode: '+48', shortName: 'PL',
  },
  {
    id: 151,
    name: 'Portugal',
    phoneCode: '+351',
    shortName: 'PT',
  },
  {
    id: 84, name: 'Puerto Rico', phoneCode: '+1', shortName: 'PR',
  },
  {
    id: 199,
    name: 'Qatar',
    phoneCode: '+974',
    shortName: 'QA',
  },
  {
    id: 152, name: 'Romania', phoneCode: '+40', shortName: 'RO',
  },
  {
    id: 153,
    name: 'Russia',
    phoneCode: '+7',
    shortName: 'RU',
  },
  {
    id: 277, name: 'Rwanda', phoneCode: '+250', shortName: 'RW',
  },
  {
    id: 354,
    name: 'Saint Kitts and Nevis',
    phoneCode: '+1 869',
    shortName: 'KN',
  },
  {
    id: 362, name: 'Saint Lucia', phoneCode: '+1 758', shortName: 'LC',
  },
  {
    id: 357,
    name: 'Saint Vincent and the Grenadines',
    phoneCode: '+1 784',
    shortName: 'VC',
  },
  {
    id: 303, name: 'Saipan', phoneCode: '+1 670', shortName: 'MP',
  },
  {
    id: 352,
    name: 'San Marino',
    phoneCode: '+378',
    shortName: 'SM',
  },
  {
    id: 201, name: 'Saudi Arabia', phoneCode: '+966', shortName: 'SA',
  },
  {
    id: 278,
    name: 'Senegal',
    phoneCode: '+221',
    shortName: 'SN',
  },
  {
    id: 155, name: 'Serbia', phoneCode: '+381', shortName: 'RS',
  },
  {
    id: 279,
    name: 'Seychelles Is.',
    phoneCode: '+248',
    shortName: 'SC',
  },
  {
    id: 280, name: 'Sierra Leone', phoneCode: '+232', shortName: 'SL',
  },
  {
    id: 202,
    name: 'Singapore',
    phoneCode: '+65',
    shortName: 'SG',
  },
  {
    id: 156, name: 'Slovakia', phoneCode: '+421', shortName: 'SK',
  },
  {
    id: 157,
    name: 'Slovenia',
    phoneCode: '+386',
    shortName: 'SI',
  },
  {
    id: 305, name: 'Solomon Islands', phoneCode: '+677', shortName: 'SB',
  },
  {
    id: 281,
    name: 'Somalia',
    phoneCode: '+252',
    shortName: 'SO',
  },
  {
    id: 282, name: 'South Africa', phoneCode: '+27', shortName: 'ZA',
  },
  {
    id: 341,
    name: 'South Korea',
    phoneCode: '+82',
    shortName: 'KR',
  },
  {
    id: 158, name: 'Spain', phoneCode: '+34', shortName: 'ES',
  },
  {
    id: 203,
    name: 'Sri Lanka',
    phoneCode: '+94',
    shortName: 'LK',
  },
  {
    id: 283, name: 'Sudan', phoneCode: '+249', shortName: 'SD',
  },
  {
    id: 113,
    name: 'Suriname',
    phoneCode: '+597',
    shortName: 'SR',
  },
  {
    id: 284, name: 'Swaziland', phoneCode: '+268', shortName: 'SZ',
  },
  {
    id: 159,
    name: 'Sweden',
    phoneCode: '+46',
    shortName: 'SE',
  },
  {
    id: 160, name: 'Switzerland', phoneCode: '+41', shortName: 'CH',
  },
  {
    id: 227,
    name: 'Syria',
    phoneCode: '+963',
    shortName: 'SY',
  },
  {
    id: 205, name: 'Taiwan', phoneCode: '+886', shortName: 'TW',
  },
  {
    id: 206,
    name: 'Tajikistan',
    phoneCode: '+992',
    shortName: 'TJ',
  },
  {
    id: 285, name: 'Tanzania', phoneCode: '+255', shortName: 'TZ',
  },
  {
    id: 207,
    name: 'Thailand',
    phoneCode: '+66',
    shortName: 'TH',
  },
  {
    id: 286, name: 'Togo', phoneCode: '+228', shortName: 'TG',
  },
  {
    id: 367,
    name: 'Tonga',
    phoneCode: '+676',
    shortName: 'TO',
  },
  {
    id: 114, name: 'Trinidad', phoneCode: '+1 868', shortName: 'TT',
  },
  {
    id: 287,
    name: 'Tunisia',
    phoneCode: '+216',
    shortName: 'TN',
  },
  {
    id: 209, name: 'Turkey', phoneCode: '+90', shortName: 'TR',
  },
  {
    id: 210,
    name: 'Turkmenistan',
    phoneCode: '+993',
    shortName: 'TM',
  },
  {
    id: 358,
    name: 'Turks and Caicos Islands',
    phoneCode: '+1 649',
    shortName: 'TC',
  },
  {
    id: 211,
    name: 'U. A. Emirates',
    phoneCode: '+971',
    shortName: 'AE',
  },
  {
    id: 288, name: 'Uganda', phoneCode: '+256', shortName: 'UG',
  },
  {
    id: 161,
    name: 'Ukraine',
    phoneCode: '+380',
    shortName: 'UA',
  },
  {
    id: 162, name: 'United Kingdom', phoneCode: '+44', shortName: 'GB',
  },
  {
    id: 115,
    name: 'Uruguay',
    phoneCode: '+598',
    shortName: 'UY',
  },
  {
    id: 1, name: 'USA', phoneCode: '+1', shortName: 'US',
  },
  {
    id: 212,
    name: 'Uzbekistan',
    phoneCode: '+998',
    shortName: 'UZ',
  },
  {
    id: 308, name: 'Vanuatu', phoneCode: '+678', shortName: 'VU',
  },
  {
    id: 116,
    name: 'Venezuela',
    phoneCode: '+58',
    shortName: 'VE',
  },
  {
    id: 213, name: 'Vietnam', phoneCode: '+84', shortName: 'VN',
  },
  {
    id: 364,
    name: 'Virgin Islands, British',
    phoneCode: '+1 284',
    shortName: 'VG',
  },
  {
    id: 355,
    name: 'Virgin Islands, U.S.',
    phoneCode: '+1 340',
    shortName: 'VI',
  },
  {
    id: 215,
    name: 'Yemen',
    phoneCode: '+967',
    shortName: 'YE',
  },
  {
    id: 290, name: 'Zaire', phoneCode: '+243', shortName: 'CD',
  },
  {
    id: 291,
    name: 'Zambia',
    phoneCode: '+260',
    shortName: 'ZM',
  },
  {
    id: 292, name: 'Zimbabwe', phoneCode: '+263', shortName: 'ZW',
  },
];

export const DEFAULT_COUNTRIES_WITH_PHONE_CODE = {
  id: 1,
  name: 'USA',
  phoneCode: '+1',
  shortName: 'US',
};
