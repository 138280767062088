import authDataAdapter from '@/features/auth/requests/adapters/auth-data-adapter';
import { type AuthDataResponse } from '@/features/auth/types/auth-resopnse-type';
import {
  type AuthData,
  type PutValidatePhoneErrorId,
} from '@/features/auth/types/auth-types';
import { type CheckPhone } from '@/features/auth/types/check-phone-types';
import sendHttpError from '@/libs/logger/requests/send-http-error';
import { type FetchResponse } from '@/shared/types/fetch-response-types';

export default async function putValidatePhone(phoneNumber: string, code: string, isPhoneRegistered: CheckPhone['isPhoneRegistered']): Promise<FetchResponse<AuthData | null, PutValidatePhoneErrorId>> {
  const url = `${process.env.NEXT_PUBLIC_API_URL}/v1/ValidatePhone?phone_number=${phoneNumber}&code=${code}`;

  const phoneValidateResponse = await fetch(
    url,
    {
      cache: 'no-store',
      method: 'PUT',
    },
  );

  // Server send 400 if code is invalid
  if (phoneValidateResponse.status === 400) {
    return {
      errorId: 'invalid-code',
      isError: true,
    };
  }

  if (!phoneValidateResponse.ok) {
    sendHttpError({
      baseName: 'Validate phone with code',
      functionName: 'putValidatePhone',
      method: 'PUT',
      statusCode: phoneValidateResponse.status,
      url,
    });

    throw new Error('Validate phone failed');
  }

  // Check if phone is not registered and return true to avoid json parse error
  if (!isPhoneRegistered) {
    return { data: null };
  }

  const data = await phoneValidateResponse.json() as AuthDataResponse;

  return { data: authDataAdapter(data) };
}
